<template>
  <v-dialog
    v-model="show"
    @click:outside="fecharVendasCaixa"
    @keydown.esc="fecharVendasCaixa"
    width="1200px"
    scrollable
  >
    <v-card outlined>
      <v-card-title class="tocs_gray_1 pa-2">
        <span class="px-2">
          {{ $tc("global.devolucao") }}
        </span>
        <v-spacer></v-spacer>
        <v-btn @click="fecharVendasCaixa" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-2">
        <div class="d-flex pa-2 mb-4">
          <v-text-field
            style="max-width: 400px"
            v-model="search"
            append-icon="mdi-magnify"
            :label="$tc('global.buscar')"
            clearable
            outlined
            solo
            flat
            dense
            hide-details
          >
          </v-text-field>
        </div>
        <v-data-table
          :headers="headers"
          :items="vendas"
          :search="search"
          :sort-desc="true"
          :items-per-page="15"
          class="data-tables data-tables__row-click"
          @click:row="openDevolucao"
        >
          <template v-slot:item.data="{ item }">
            {{ item.data | dateFormat("dd/MM/yyyy") }}
          </template>
          <template v-slot:item.condicao_pagamento="{ item }">
            {{
              item.condicao_pagamento == 1
                ? $tc("global.aVista")
                : $tc("global.aPrazo")
            }}
          </template>
          <template v-slot:item.valor="{ item }">
            {{ item.moeda_sigla }}
            <span v-if="item.moeda_sigla === 'G$'">
              {{ item.valor | guarani }}
            </span>
            <span v-else>
              {{ item.valor | currency }}
            </span>
          </template>
          <template v-slot:item.desconto="{ item }">
            {{ item.moeda_sigla }}
            <span v-if="item.moeda_sigla === 'G$'">
              {{ item.desconto | guarani }}
            </span>
            <span v-else>
              {{ item.desconto | currency }}
            </span>
          </template>
          <template v-slot:item.total="{ item }">
            {{ item.moeda_sigla }}
            <span v-if="item.moeda_sigla === 'G$'">
              {{ item.total | guarani }}
            </span>
            <span v-else>
              {{ item.total | currency }}
            </span>
          </template>
          <template v-slot:item.cliente_nome="{ item }">
            <v-tooltip bottom color="darkblue">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  {{ item.cliente_nome | excerpt(20) }}
                </div>
              </template>
              <span>{{ item.cliente_nome }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.vendedor_nome="{ item }">
            <v-tooltip bottom color="darkblue">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  {{ item.vendedor_nome | excerpt(20) }}
                </div>
              </template>
              <span>{{ item.vendedor_nome }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
      <DialogDevolucaoEmLoteAf
        v-if="dialogDevolucao"
        :show.sync="dialogDevolucao"
        :item_id="item_id"
        tipo_devolucao="vendas"
        @fetch-devolucoes="fetchVendas"
        @add="addItem"
        @fechar="fechar"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { getVendas } from "@/api/vendas/vendas.js";

export default {
  name: "DialogDevolucoesEmLoteAf",

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    cliente: {
      type: Object,
      default: null,
    },
  },

  components: {
    DialogDevolucaoEmLoteAf: () => import("../DialogDevolucaoEmLoteAf.vue"),
  },

  data() {
    return {
      vendas: [],
      search: "",
      venda: {},
      venda_parcelas: [],
      dialogBaixaVenda: false,
      dialogDevolucao: false,
      dialogVendaInfo: false,
      motivo_devolucao: "",
      devolucao_id: null,
      item_id: null,
    };
  },

  computed: {
    headers() {
      return [
        {
          text: this.$tc("global.numero"),
          width: "60px",
          align: "center",
          sortable: false,
          value: "numero",
          divider: true,
        },
        {
          text: this.$tc("global.data"),
          value: "data",
          divider: true,
        },
        {
          text: this.$tc("global.cliente"),
          width: "180px",
          value: "cliente_nome",
          divider: true,
        },
        {
          text: this.$tc("global.valor"),
          width: "130px",
          value: "valor",
          divider: true,
        },
        {
          text: this.$tc("global.desconto"),
          value: "desconto",
          width: "100px",
          align: "start",
          sortable: false,
          divider: true,
        },

        {
          text: this.$tc("global.total"),
          width: "150px",
          align: "right",
          sortable: false,
          value: "total",
          divider: true,
        },
      ];
    },
  },

  methods: {
    fechar() {
      this.$emit("update:show", false);
    },
    addItem(item) {
      this.$emit("add", item);
    },
    openDevolucao(item) {
      this.dialogDevolucao = true;
      this.item_id = item.id;
    },
    addVenda(transacao) {
      this.$emit("add", transacao);
    },
    fetchVendas() {
      this.loading = true;
      getVendas("?status_filter=5&condicao_pagamento=1")
        .then((response) => {
          this.vendas = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    fetchVendasCliente() {
      this.loading = true;
      getVendas("?status_filter=5&cliente_id=" + this.cliente.id)
        .then((response) => {
          this.vendas = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    fecharVendasCaixa() {
      this.$emit("update:show", false);
    },
  },
  mounted() {
    if (this.cliente) {
      this.fetchVendasCliente();
    } else {
      this.fetchVendas();
    }
  },
};
</script>

<style></style>
