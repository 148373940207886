var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"1200px","scrollable":""},on:{"click:outside":_vm.fecharVendasCaixa,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.fecharVendasCaixa.apply(null, arguments)}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"tocs_gray_1 pa-2"},[_c('span',{staticClass:"px-2"},[_vm._v(" "+_vm._s(_vm.$tc("global.devolucao"))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.fecharVendasCaixa}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-2"},[_c('div',{staticClass:"d-flex pa-2 mb-4"},[_c('v-text-field',{staticStyle:{"max-width":"400px"},attrs:{"append-icon":"mdi-magnify","label":_vm.$tc('global.buscar'),"clearable":"","outlined":"","solo":"","flat":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"data-tables data-tables__row-click",attrs:{"headers":_vm.headers,"items":_vm.vendas,"search":_vm.search,"sort-desc":true,"items-per-page":15},on:{"click:row":_vm.openDevolucao},scopedSlots:_vm._u([{key:"item.data",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.data,"dd/MM/yyyy"))+" ")]}},{key:"item.condicao_pagamento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.condicao_pagamento == 1 ? _vm.$tc("global.aVista") : _vm.$tc("global.aPrazo"))+" ")]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.moeda_sigla)+" "),(item.moeda_sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.valor))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.valor))+" ")])]}},{key:"item.desconto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.moeda_sigla)+" "),(item.moeda_sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.desconto))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.desconto))+" ")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.moeda_sigla)+" "),(item.moeda_sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.total))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")])]}},{key:"item.cliente_nome",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"darkblue"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(" "+_vm._s(_vm._f("excerpt")(item.cliente_nome,20))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.cliente_nome))])])]}},{key:"item.vendedor_nome",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"darkblue"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(" "+_vm._s(_vm._f("excerpt")(item.vendedor_nome,20))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.vendedor_nome))])])]}}])})],1),(_vm.dialogDevolucao)?_c('DialogDevolucaoEmLoteAf',{attrs:{"show":_vm.dialogDevolucao,"item_id":_vm.item_id,"tipo_devolucao":"vendas"},on:{"update:show":function($event){_vm.dialogDevolucao=$event},"fetch-devolucoes":_vm.fetchVendas,"add":_vm.addItem,"fechar":_vm.fechar}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }